<template>
    <div class="chat-messages">
        <div class="chat-messages__header">
            <div class="chat-messages__header-content">
                <div class="chat-messages__header-title">{{ room.title }}</div>
                <p class="chat-messages__header-name">{{ room.full_name }}</p>
            </div>

            <div v-if="room.can_close">
                <div v-if="room.is_closed" @click="emit('toggleChat')" class="chat-messages__header-btn">
                    <img src="@/assets/images/icons/lock-open-blue.svg" alt="lock">
                    <p class="chat-messages__header-btn-label chat-messages__header-btn-label_open">Открыть чат</p>
                </div>

                <div v-else @click="emit('toggleChat')" class="chat-messages__header-btn">
                    <img src="@/assets/images/icons/lock-red.svg" alt="lock">
                    <p class="chat-messages__header-btn-label chat-messages__header-btn-label_close">Закрыть чат</p>
                </div>
            </div>
        </div>

        <div ref="messagesContainer" class="chat-messages__messages">
            <div class="chat-messages__messages-wrapper">
                <div v-for="(message, index) in messages" :key="message.data.id" class="chat-messages__message"
                    :class="{
                    'chat-messages__message_you': !message.data.is_me_sender,
                    'chat-messages__message_me': message.data.is_me_sender,
                    'chat-messages__message_margin-top': (message.type === 'MESSAGE' && !message.data.is_me_sender && ((messages[index + 1] && messages[index + 1].data.author_type !== message.data.author_type) || index === messages.length - 1)),
                    'chat-messages__message_margin-bottom': (!message.data.is_me_sender && (messages[index - 1] && messages[index - 1].data.author_type !== message.data.author_type))
                }">
                    <p v-if="message.type === 'SEPARATOR'" class="chat-messages__message-separator">{{
                    message.data.title
                }}</p>
                    <div v-if="message.type === 'MESSAGE' && !message.data.is_me_sender
                    && ((messages[index + 1] && messages[index + 1].data.author_type !== message.data.author_type)
                        || index === messages.length - 1)" class="chat-messages__message-ava">
                        <img v-if="message.data.author_type === 'DIRECTION_LEADER'" src="@/assets/images/icons/opn.svg"
                            alt="settings">

                        <img v-if="message.data.author_type === 'USER'" src="@/assets/images/icons/user.svg"
                            alt="settings">

                        <img v-if="message.data.author_type === 'HR'" src="@/assets/images/icons/hr-chat.svg"
                            alt="settings">

                        <img v-if="message.data.author_type === 'SECURITY_SERVICE'"
                            src="@/assets/images/icons/ss-border.svg" alt="settings">
                    </div>
                    <div v-if="message.type === 'MESSAGE'" class="chat-messages__message-wrapper">
                        <div v-if="!message.data.is_me_sender && messages[index - 1].data.author_type !== message.data.author_type"
                            class="chat-messages__message-role">
                            <p v-if="message.data.author_type === 'USER'">Соискатель</p>
                            <p v-if="message.data.author_type === 'HR'">HR</p>
                            <p v-if="message.data.author_type === 'DIRECTION_LEADER'">ОПН</p>
                            <p v-if="message.data.author_type === 'SECURITY_SERVICE'">СБ</p>
                        </div>

                        <div class="chat-messages__message-block-wrapper">
                            <div v-if="!message.data.message_attachment" class="chat-messages__message-block">
                                {{ message.data.message_text.trim() }}
                            </div>
                            <div v-if="message.data.message_attachment" class="chat-messages__message-file">
                                <a v-if="!message.offline && !message.loads"
                                    :href="message.data.message_attachment.path" target="_blank"
                                    class="chat-messages__message-file-left">
                                    <img v-if="message.data.message_attachment.type === 'PDF_DOCUMENT'"
                                        src="@/assets/images/icons/document-black-icon.svg" alt="document">
                                    <img v-if="message.data.message_attachment.type !== 'PDF_DOCUMENT'"
                                        :src="message.data.message_attachment.path" alt="document">
                                </a>
                                <div v-if="message.loads" @click="stopRequest" class="chat-messages__message-file-left">
                                    <div class="chat-messages__message-file-loader">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>

                                <div v-if="message.offline" @click="emit('repeatMessage', message.data)"
                                    class="chat-messages__message-file-left">
                                    <img src="@/assets/images/icons/upgrade.svg" alt="document">
                                </div>

                                <div class="chat-messages__message-file-content">
                                    <div class="chat-messages__message-file-name">
                                        {{ truncateText(message.data.message_attachment.name) }}
                                    </div>
                                    <p class="chat-messages__message-file-size">
                                        {{ message.data.message_attachment.size }}</p>
                                </div>
                            </div>
                            <div v-show="message.offline" @click="errorMessagePopupActive = message.data.message_code"
                                class="chat-messages__message-block-btn">
                                <img src="@/assets/images/icons/alert.svg" alt="alert">
                            </div>

                            <ErrorMessagePopup v-if="errorMessagePopupActive === message.data.message_code"
                                @close="errorMessagePopupActive = null" @repeat="emit('repeatMessage', message.data)"
                                @remove="emit('removeMessage', message.data.message_code)"
                                class="chat-messages__message-block-popup" />
                        </div>

                        <div v-if="message.type === 'MESSAGE' &&
                    (messages[index + 1] && messages[index + 1].data.time !== message.data.time) ||
                    index === messages.length - 1 || messages[index + 1].data.is_me_sender !== message.data.is_me_sender"
                            class="chat-messages__message-text">
                            <p>{{ message.data.time }}</p>
                            <img v-if="message.data.time && message.data.is_me_sender && !message.loads"
                                src="@/assets/images/icons/check.svg" alt="check">
                            <img v-if="message.data.time && message.data.is_me_sender && message.loads"
                                src="@/assets/images/icons/msg-clock.svg" alt="check">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="room.is_closed" class="chat-messages__footer-closed">
            <div class="chat-messages__footer-closed-block">
                Чат закрыт
            </div>
        </div>

        <form v-else @submit.prevent="sendMessage" class="chat-messages__footer">
            <div class="chat-messages__footer-plus">
                <input @change="sendFile" type="file" accept=".jpg,.jpeg,.png,.pdf">
                <img src="@/assets/images/icons/plus-blue-chat.svg" alt="plus">
            </div>
            <textarea ref="textarea" @input="autoResizeTextarea" v-model="message" placeholder="Введите сообщение"
                class="chat-messages__footer-inp"></textarea>
            <button class="chat-messages__footer-send" :class="{ 'chat-messages__footer-send_active': disabled }">
                <img src="@/assets/images/icons/send.svg" alt="send">
            </button>
        </form>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import ErrorMessagePopup from '../ErrorMessagePopup'
import api from "@/api/api";

const props = defineProps({
    messages: Array,
    room: Object
})

const emit = defineEmits(['sendMessage', 'toggleChat', 'repeatMessage', 'removeMessage', 'sendFile'])

const messagesContainer = ref(null)

const errorMessagePopupActive = ref(null)

watch(() => props.messages, (state, prevState) => {
    setTimeout(() => {
        messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight
    }, 0)
}, { deep: true })

const message = ref('')

const disabled = computed(() => message.value.trim().length > 0)
const sendMessage = () => {
    if (disabled.value) {
        emit('sendMessage', message.value.trim())
        message.value = ''
        textarea.value.style.height = '48px';
        messagesContainer.value.style.paddingBottom = '16px';
    }
}
const maxImageSize = 10000000

const code = ref(null)
const sendFile = async (e) => {
    code.value = Math.floor(Math.random() * (100000000 - 1000000 + 1)) + 1000;
    const formData = new FormData();

    if (e.target.files[0].type !== 'image/png' &&
        e.target.files[0].type !== 'image/jpg' &&
        e.target.files[0].type !== 'image/jpeg' &&
        e.target.files[0].type !== 'application/pdf') return

    if (e.target.files[0].size < maxImageSize) {
        formData.append('PHP_SESSION_UPLOAD_PROGRESS', code.value);
        formData.append('attachment', e.target.files[0]);
        formData.append('message_code', Math.floor(Math.random() * (100000000 - 1000000 + 1)) + 1000);
        emit('sendFile', formData)
    }
}
const stopRequest = async () => {
    const response = await api.post(`${process.env.VUE_APP_BASE}/progress-file`, {
        PHP_SESSION_UPLOAD_PROGRESS: code.value,
        cancel: true
    })

}

const truncateText = computed(() => {
    return (text) => {
        if (text.length > 20) {
            const middleIndex = Math.floor(text.length / 8)
            return text.substring(0, middleIndex) + '...' + text.substring(text.length - 7)
        } else {
            return text
        }
    }
})

const textarea = ref(null)
const autoResizeTextarea = () => {
    textarea.value.style.height = '48px';
    textarea.value.style.height = textarea.value.scrollHeight + 'px';
    messagesContainer.value.style.paddingBottom = (textarea.value.offsetHeight - 30) + 'px';
}

onMounted(() => {
    messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight
})
</script>

<style scoped lang="scss">
.chat-messages {
    overflow-y: auto;
    position: relative;

    &__header {
        width: 100%;
        height: 72px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $light-gray-1;
    }

    &__header-title {
        font-size: 16px;
        font-weight: 500;
    }

    &__header-name {
        margin-top: 4px;
        color: $gray;
        font-size: 14px;
        max-width: 350px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__header-btn {
        padding: 4px 8px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        transition: .3s;

        img {
            margin-right: 4px;
        }

        &:hover {
            background-color: $light;
        }

        &:active {
            background-color: $light-gray-1;
        }
    }

    &__header-btn-label {
        font-size: 14px;
        font-weight: 500;

        &_close {
            color: $red;
        }

        &_open {
            color: $blue;
        }
    }

    &__messages {
        padding: 0 16px 0 16px;
        height: 467px;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            width: 0;
        }
    }

    &__messages-wrapper {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__message {
        position: relative;
        width: 100%;
        display: flex;
        align-items: flex-end;
        //overflow: hidden;

        &:not(:first-child) {
            margin-top: 8px;
        }

        &_you {
            justify-content: flex-start;
        }

        &_me {
            justify-content: flex-end;
        }

        &_margin-top {
            padding-bottom: 15px;

            &:last-child {
                padding-bottom: 0;
            }
        }

        &_margin-bottom {
            padding-top: 15px;
        }
    }

    &__message-ava {
        position: absolute;
        width: 36px;
        height: 36px;
        margin-right: 8px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light-blue;

        img {
            width: 24px;
        }
    }

    &__message_me &__message-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__message_you &__message-wrapper {
        margin-left: 44px;
    }

    &__message-separator {
        margin-top: 16px;
        width: 100%;
        color: $gray;
        text-align: center;
        font-size: 14px;
    }

    &__message-block-wrapper {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__message-file {
        max-width: 272px;
        padding: 8px 12px;
        display: flex;
        align-items: center;
    }

    &__message_you &__message-file {
        border-radius: 8px 8px 8px 0;
        background-color: $light-blue;
    }

    &__message_me &__message-file {
        border-radius: 8px 8px 0 8px;
        background-color: $primary-chats;
    }

    &__message-file-left {
        margin-top: 3px;
        width: 24px;
        height: 24px;

        img {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            object-fit: cover;
        }
    }

    &__message-file-loader {
        display: inline-block;
        position: relative;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    &__message-file-loader-stop {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 7.5px;
        height: 7.5px;
        border-radius: 2px;
        background-color: $dark;
    }

    &__message-file-loader div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        border: 1px solid $dark;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $dark transparent transparent transparent;
    }

    &__message-file-loader div:nth-child(1) {
        animation-delay: -0.45s;
    }

    &__message-file-loader div:nth-child(2) {
        animation-delay: -0.3s;
    }

    &__message-file-loader div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    &__message-file-content {
        margin-left: 8px;
    }

    &__message-file-name {
        max-width: 220px;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__message-file-size {
        margin-top: 4px;
        color: $gray;
        font-size: 12px;
        font-weight: 400;
    }

    &__message-block {
        max-width: 460px;
        padding: 8px 12px;
        display: inline-flex;
        font-size: 16px;
        //overflow-wrap: break-word;
        overflow: hidden;
        white-space: pre-wrap;
    }

    &__message-block-btn {
        margin-left: 8px;
        cursor: pointer;
    }

    &__message-block-popup {
        width: 211px;
        position: absolute;
        right: 30px;
        top: -50px;
        z-index: 99;
    }

    &__message_you &__message-block {
        border-radius: 8px 8px 8px 0px;
        background-color: $light-blue;
    }

    &__message_me &__message-block {
        border-radius: 8px 8px 0px 8px;
        background-color: $primary-chats;
    }

    &__message-text {
        margin-top: 4px;
        color: $gray;
        display: flex;
        align-items: center;
        font-size: 12px;

        img {
            margin-left: 4px;
        }
    }

    &__message-role {
        margin-bottom: 8px;
        color: $gray;
        display: flex;
        align-items: center;
        font-size: 12px;
    }

    &__footer-closed {
        position: absolute;
        bottom: 16px;
        left: 0;
        width: calc(100% - 32px);
        height: 48px;
        margin: 16px 16px 0 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light;
        font-size: 14px;
    }

    &__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px 16px 16px 16px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        background-color: #fff;
    }

    &__footer-inp {
        width: calc(100% - 104px);
        height: 48px;
        max-height: 127px;
        padding-left: 16px;
        padding-top: 15.5px;
        padding-bottom: 15.5px;
        border-radius: 8px;
        background-color: $light;
        resize: none;

        &::placeholder {
            color: $gray;
            font-size: 14px;
            font-weight: 400;
        }
    }

    &__footer-plus {
        position: relative;
        height: 48px;
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        cursor: pointer;

        img {
            width: 32px;
            cursor: pointer;
            //z-index: -1;
        }

        input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
            z-index: 99;

            &::-webkit-file-upload-button {
                cursor: pointer;
            }
        }
    }

    &__footer-send {
        width: 48px;
        height: 48px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #BBC4CD;
        border: none;
        cursor: default;

        &_active {
            background-color: $blue;
            cursor: pointer;
        }
    }
}
</style>
