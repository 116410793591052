<template>
  <div class="modal-body">
    <div class="modal-body__header">
      <div class="modal-body__header-title">История согласования</div>
      <div class="modal-body__close" @click="$emit('close')">
        <img src="@/assets/images/icons/x.svg" alt="close" />
      </div>
    </div>
    <div class="modal-body__content">
      <div
        class="modal-body__route"
        v-for="(route, index) in history"
        :key="index"
      >
        <div class="modal-body__route-title">
          <!-- <img
                        v-if="route.status === 'PROCESSING' || route.status === 'WAITING' || route.status === 'NOT_AVAILABLE'"
                        src="@/assets/images/icons/document-black.svg"
                        alt="check"
                        class="modal-body__route-icon">

                    <img
                        v-if="route.status === 'ACCEPTED'"
                        src="@/assets/images/icons/check-green-cricle.svg"
                        alt="check"
                        class="modal-body__route-icon">


                    <img
                        v-if="route.status === 'NOT_ACCEPTED'"
                        src="@/assets/images/icons/x-red-cricle.svg"
                        alt="check"
                        class="modal-body__route-icon"> -->
          <p class="modal-body__route-title-text">{{ route.stage }}</p>
        </div>

        <div class="modal-body__route-stages">
          <div
            class="modal-body__route-history"
            v-for="(history, i) in route.history"
            :key="i"
          >
            <div class="modal-body__route-history-title">
              {{ history.event }}
            </div>
            <div
              class="modal-body__route-history-rejection"
              v-if="history?.rejection_reason"
            >
              Причина : {{ history.rejection_reason }}
            </div>
            <div class="modal-body__route-history-row">
              <p class="modal-body__route-history-text">{{ history.actor }}</p>
              <p class="modal-body__route-history-text">
                {{ dateTimeFormaterToText(history.created_at) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import responses from "@/api/responses";

const props = defineProps({
  id: [Number, String],
});

const history = ref([]);

const dateTimeFormaterToText = (stringDate) => {
  const date = new Date(stringDate);
  return `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}.${
    date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)
  }.${date.getFullYear()} ${
    date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
  }:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;
};

const getHistory = async () => {
  try {
    const res = await responses.getHistory(props.id);
    history.value = res?.history;
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  getHistory();
});
</script>

<style scoped lang="scss">
.modal-body {
  overflow: hidden;
  border-radius: 16px;

  &__header {
    position: relative;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-bottom: 1px solid $light-gray-1;
  }

  &__header-title {
    font-size: 16px;
    font-weight: 600;
  }

  &__close {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    img {
      width: 24px;
    }
  }

  &__content {
    height: 604px;
    padding-bottom: 24px;
    padding-top: 24px;
    overflow: auto;
  }

  &__route {
    padding: 0 24px;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__route-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    img {
      width: 12px;
    }

    p {
      margin-left: 8px;
      font-size: 16px;
      font-weight: 600;
      color: $dark;
      line-height: 19.36px;
      letter-spacing: 0%;
    }
  }

  &__route-stages {
    margin-left: 16px;
    margin-top: 16px;
    padding-left: 16px;
    border-left: 1px solid $light-gray-1;
  }

  &__route-history {
    width: 100%;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: $light;

    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__route-history-title {
    font-size: 14px;
  }
  &__route-history-rejection {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    letter-spacing: 0%;
    color: $dark-blue;
  }

  &__route-history-row {
    width: 100%;
    margin-top: 4px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__route-history-text {
    max-width: 80%;
    color: $gray;
    font-size: 12px;
    line-height: 14.52px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}
</style>
